import axios from "axios"

export const createCheckoutSession = async (
  donationItemIds,
  isCoveringFees,
  inHonorOf,
  subscribeMail,
  giftCardCode,
  campaign
) => {
  const {
    data: { id },
  } = await axios.post("/.netlify/functions/create-checkout", {
    donationItemIds,
    isCoveringFees,
    inHonorOf,
    subscribeMail,
    giftCardCode,
    campaign: campaign ?? undefined,
  })
  return id
}

export const getMatchedFamily = async () => {
  return await axios.get("/.netlify/functions/match-family")
}

export const makeGeneralFundDonation = async (
  value,
  recurring,
  subscribeMail,
  name,
  email,
  isCoveringFees
) => {
  const {
    data: { id },
  } = await axios.post("/.netlify/functions/create-general-fund-donation", {
    value,
    recurring,
    subscribeMail,
    name,
    email,
    isCoveringFees,
  })
  return id
}

export const makeGiftCardPurchase = async (
  value,
  subscribeMail,
  senderName,
  recipientName,
  deliveryEmail,
  isCoveringFees
) => {
  const {
    data: { id },
  } = await axios.post("/.netlify/functions/create-gift-card-purchase", {
    value,
    subscribeMail,
    senderName,
    recipientName,
    deliveryEmail,
    isCoveringFees,
  })
  return id
}

export const makeBulkGiftCardPurchase = async (
  value,
  subscribeMail,
  bulkPurchaserName,
  isCoveringFees
) => {
  const {
    data: { id },
  } = await axios.post("/.netlify/functions/create-gift-card-bulk-purchase", {
    value,
    subscribeMail,
    bulkPurchaserName,
    isCoveringFees,
  })
  return id
}

export const createGiftCardRedeem = async (
  donationItemIds,
  isCoveringFees,
  inHonorOf,
  subscribeMail,
  email,
  giftCardCode,
  totalPledged
) => {
  const { data: result } = await axios.post(
    "/.netlify/functions/create-gift-card-redeem",
    {
      donationItemIds,
      isCoveringFees,
      inHonorOf,
      subscribeMail,
      email,
      giftCardCode,
      totalPledged,
    }
  )
  return result
}

export const checkGiftCardBalance = async giftCardCode => {
  const { data: result } = await axios.post(
    "/.netlify/functions/check-gift-card-balance",
    {
      giftCardCode,
    }
  )
  return result
}

export const createUserManagementSession = async (token, email) => {
  const {
    data: { portalUrl },
  } = await axios.post("/.netlify/functions/manage-subscription", {
    email,
    token,
  })
  return portalUrl
}
