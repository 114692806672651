import { Location, navigate } from "@reach/router"
import queryString from "query-string"
import React from "react"
import { getMatchedFamily } from "../api"

export const getAllFamiliesIds = data => {
  return (
    data &&
    data.reduce((acc, family) => {
      acc.push(family.family_id)
      return acc
    }, [])
  )
}

export const navigateToFamily = ({ id, familyIds, campaign }) => {
  let familyId
  if (!id && familyIds) {
    const randomIndex = Math.floor(
      Math.random() * Math.floor(familyIds.length - 1)
    )
    familyId = familyIds[randomIndex]
  } else if (id) {
    familyId = id
  } else {
    throw Error("Please provide either a family _id or an array of ids")
  }

  const queryParameters = campaign ? `?campaign=${campaign}` : ""

  navigate(`/family/${familyId}${queryParameters}`)
}

export const handleExploreNeeds = () => {
  navigate("/#explore-needs")
}
export const handleGetMatched = () => {
  try {
    getMatchedFamily().then(res => {
      navigate(`/family/${res.data}`)
    })
  } catch {
    navigate("/#explore-needs")
  }
}

export const handleSuggestions = (families, count = 3) => {
  if (families.length === 0) return []
  let result = new Array(count)
  let max = families.length
  let taken = new Array(max)

  if (count > max) {
    console.warn("getRandom: more elements taken than available")
    return families
  }

  while (count--) {
    let x = Math.floor(Math.random() * max)
    result[count] = families[x in taken ? taken[x] : x]
    taken[x] = --max in taken ? taken[max] : max
  }
  return result
}

export const generateGeoJSON = (lat, long) => {
  return {
    type: "Point",
    coordinates: [long, lat],
  }
}

export const withLocation = ComponentToWrap => props => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
)
