import { loadStripe } from "@stripe/stripe-js"
import { navigate } from "gatsby"
import { createCheckoutSession } from "../api"

const stripePublicKey = process.env.GATSBY_STRIPE_PUBLIC_KEY

const redirectToCheckout = async (
  donationItemIds,
  isCoveringFees,
  honoreeData,
  subscribeMail,
  giftCardCode,
  campaign
) => {
  if (!donationItemIds) {
    // TODO: expose and handle bad data error
    console.warn(`invalid checkout: ${donationItemIds}`)
  }

  const stripe = await loadStripe(stripePublicKey)

  const sessionId = await createCheckoutSession(
    donationItemIds,
    isCoveringFees,
    honoreeData,
    subscribeMail,
    giftCardCode,
    campaign
  )

  const { error } = await stripe.redirectToCheckout({
    sessionId,
  })

  if (error) {
    navigate("/error-page")
  }
}

export default redirectToCheckout
