import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import Ctx from "../../context"
// components
import CardFamily from "../CardFamily"
import FamiliesFilter from "../FamiliesFilter"
import Loading from "../Loading"

// function
import Modal, { ModalType } from "../Modal"

import styles from "./styles.module.scss"

const GridItems = ({ allFamilies, campaign }) => (
  <div className={styles.helpCardWrapper}>
    {allFamilies.map((family, index) => (
      <CardFamily data={family} key={index} campaign={campaign} />
    ))}
  </div>
)

const FIRST_CHUNK = 1
const SUBSEQUENT_CHUNKS = 10

const FamiliesGrid = ({
  sponsor = null,
  organizations = null,
  organizationText = null,
  campaign = null,
}) => {
  const ctx = useContext(Ctx)
  const [chunkSize, setChunkSize] = useState(FIRST_CHUNK)
  const [generalFundOpen, setGeneralFundOpen] = useState(false)

  const handleLoadMore = () => {
    setChunkSize(chunkSize + SUBSEQUENT_CHUNKS)
  }
  const title =
    ctx.allFamilies.value.length > 0
      ? `Meet households in need`
      : `Meet families in need`

  if (!ctx.allFamilies.value) {
    // context not loaded yet
    return <Loading />
  }

  let sponsor_unhidden_organizations = sponsor
    ? JSON.parse(sponsor.unhidden_organizations)
    : null

  const unhiddenOrganizations = organizations
    ? organizations
    : sponsor_unhidden_organizations || []

  const unhiddenFamilies = ctx.allFamilies.value.filter(
    family =>
      !family.organization.hidden ||
      unhiddenOrganizations.includes(family.organization.organization_id)
  )

  return (
    <>
      {unhiddenFamilies.length > 0 || ctx.filters.filtersActive() === true ? (
        <section id="explore-needs" className={styles.familiesGridContainer}>
          <div className={styles.familiesGridInner}>
            <FamiliesFilter
              sponsor={sponsor}
              prefilteredOrganizations={organizations}
              organizationText={organizationText}
            />

            <GridItems allFamilies={unhiddenFamilies} campaign={campaign} />
            {/* <div className={styles.button}>
              {unhiddenFamilies.length > chunkSize && (
                <ButtonCustom
                  handleClick={handleLoadMore}
                  hierarchy="secondary"
                  size="large"
                  icon="unclassified"
                  className={styles.inlineButton}
                  iconClassName={styles.seeMoreIcon}
                >
                  Meet more Households
                </ButtonCustom>
              )}
            </div> */}
          </div>
        </section>
      ) : (
        <section className={styles.intro}>
          <Modal
            modalType={ModalType.GeneralFund}
            modalOpen={generalFundOpen}
            handleModalClose={() => {
              setGeneralFundOpen(false)
            }}
          />
          <p className="paragraph body-copy">
            Our partner organizations are working hard to spotlight more
            households in need of urgent help.
          </p>
          <br />
          <p className="paragraph body-copy">
            Donate to the NeighborShare fund today and NeighborShare will select
            households to help on your behalf as soon as new needs are posted.
          </p>
          <button
            className={styles.donateBtn}
            onClick={() => {
              setGeneralFundOpen(true)
            }}
          >
            Donate
          </button>
        </section>
      )}
    </>
  )
}

FamiliesGrid.propTypes = {
  campaign: PropTypes.oneOf(["disaster", "fairfield_synchrony"]),
}

export default FamiliesGrid
