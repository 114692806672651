import React, { useContext, useEffect, useState } from "react"
import Ctx from "../../context"
// components
import CardFamily from "../CardFamily"
import GridSection from "../GridSection"
// functions
import { handleSuggestions } from "../../utils"

const stylingForItems = {
  marginLeft: "auto",
  marginRight: "auto",
}

const Suggestions = ({ data, campaign }) => (
  <ul>
    {data.map((familyData, index) => (
      <li key={`suggestions-${index}`} style={stylingForItems}>
        <CardFamily data={familyData} campaign={campaign} />
      </li>
    ))}
  </ul>
)

const FamilySuggestions = ({ title, currentFamilyId, campaign }) => {
  const { allFamilies } = useContext(Ctx) // Always filter out inactive partners. This isn't shown on landing pages
  const [filteredFamilies, setFilteredFamilies] = useState(null)

  useEffect(() => {
    if (!allFamilies) return

    const unhiddenFamilies = allFamilies.value.filter(
      family => !family.organization.hidden
    )

    if (!currentFamilyId) {
      setFilteredFamilies(unhiddenFamilies)
      return
    }

    // filters out the family that is already showing
    const filtered = unhiddenFamilies.filter(
      fam => fam.family_id !== currentFamilyId
    )
    setFilteredFamilies(filtered)
  }, [allFamilies, currentFamilyId])

  return (
    <GridSection title={title} bgColor="cream">
      {filteredFamilies && (
        <Suggestions
          data={handleSuggestions(filteredFamilies)}
          campaign={campaign}
        />
      )}
    </GridSection>
  )
}

export default FamilySuggestions
